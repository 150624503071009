import { Controller } from "@hotwired/stimulus"
import { disable, hide } from "../utils"

export default class extends Controller {
  static targets = ["btn", "error", "form", "text"]
  btnTarget: HTMLButtonElement
  errorTarget: HTMLElement
  textTarget: HTMLDivElement
  formTarget: HTMLFormElement
  hasBtnTarget: boolean
  hasErrorTarget: boolean
  hasTextTarget: boolean

  handleFieldChange() {
    if (this.hasErrorTarget && !this.errorTarget.classList.contains("hidden")) {
      hide(this.errorTarget)
    }
  }

  submit(): void {
    if (this.hasBtnTarget) {
      disable(this.btnTarget, "cursor-not-allowed")

      // Avoids collapse when clearing text by setting initial dimensions
      this.btnTarget.style.height = `${this.btnTarget.offsetHeight}px`
      this.btnTarget.style.width = `${this.btnTarget.offsetWidth}px`
      this.btnTarget.style.justifyContent = "center"
    }

    if (this.hasTextTarget) {
      this.textTarget.innerText = ""

      const spinnerIcon = document.createElement("i")
      spinnerIcon.className = "fas fa-spinner fa-spin"
      this.btnTarget.appendChild(spinnerIcon)
    }
  }

  submitForm(): void {
    this.formTarget.requestSubmit()
  }
}
